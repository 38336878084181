
// vue
import { computed, ref } from 'vue';

// icons
import { location } from 'ionicons/icons';

// components
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent,
        IonGrid, IonCol, IonRow, IonSpinner, IonButtons, IonButton, IonIcon, IonBackButton,
        IonItem, IonLabel, IonList, IonListHeader, IonThumbnail, IonBadge, } from '@ionic/vue';

// composables
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { utils } from '@/composables/utils';

export default {
  name: 'OrderDetailPage',
  components: { IonHeader, IonToolbar, IonTitle, IonContent, IonPage,
                IonGrid, IonCol, IonRow, IonSpinner, IonButtons, IonButton, IonIcon, IonBackButton,
                IonItem, IonLabel, IonList, IonListHeader, IonThumbnail, IonBadge, },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const route = useRoute();
    const { id, } = route.params; // order ID

    const { getOrderStatusColor, openImageModal, addResizeUrlParams, } = utils();

    // 1. declare state variables (ref to make them reactive)
    const loading = computed(() => store.state.loadingAppUserData);
    const order = computed(() => store.getters.getOrderById(id));
  
    return {
      // icons
      location,

      // variables
      loading, order,

      // methods
      t, getOrderStatusColor,
      openImageModal,
      addResizeUrlParams,
    }
  },
}
