import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "spin"
}
const _hoisted_2 = ["src"]
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_badge = _resolveComponent("ion-badge")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_ion_list_header = _resolveComponent("ion-list-header")!
  const _component_ion_thumbnail = _resolveComponent("ion-thumbnail")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_grid, { fixed: "" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_toolbar, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_buttons, { slot: "start" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_back_button, { "default-href": "/orders" })
                    ]),
                    _: 1
                  }),
                  ($setup.loading)
                    ? (_openBlock(), _createBlock(_component_ion_title, { key: 0 }, {
                        default: _withCtx(() => [
                          _createTextVNode("...")
                        ]),
                        _: 1
                      }))
                    : (_openBlock(), _createBlock(_component_ion_title, { key: 1 }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString($setup.t('OrderPage.order')) + " #" + _toDisplayString($setup.order.id), 1)
                        ]),
                        _: 1
                      })),
                  (!$setup.loading)
                    ? (_openBlock(), _createBlock(_component_ion_badge, {
                        key: 2,
                        slot: "end",
                        color: $setup.getOrderStatusColor($setup.order.status)
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString($setup.order.status), 1)
                        ]),
                        _: 1
                      }, 8, ["color"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, { fullscreen: true }, {
        default: _withCtx(() => [
          ($setup.loading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_ion_spinner)
              ]))
            : (_openBlock(), _createBlock(_component_ion_grid, {
                key: 1,
                fixed: ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_row, { class: "ion-justify-content-center" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_col, {
                        size: "12",
                        "size-md": "8",
                        "size-lg": "6"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_list, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_list_header, { color: "primary" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString($setup.t('OrderPage.orderItems')), 1)
                                ]),
                                _: 1
                              }),
                              _createElementVNode("div", null, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.order.items, (item) => {
                                  return (_openBlock(), _createBlock(_component_ion_item, {
                                    key: item.productId
                                  }, {
                                    default: _withCtx(() => [
                                      (item.productImage)
                                        ? (_openBlock(), _createBlock(_component_ion_thumbnail, {
                                            key: 0,
                                            slot: "start",
                                            onClick: ($event: any) => ($setup.openImageModal(item.productImage, item.productName))
                                          }, {
                                            default: _withCtx(() => [
                                              _createElementVNode("img", {
                                                style: {"object-fit":"contain"},
                                                src: $setup.addResizeUrlParams(item.productImage, 300)
                                              }, null, 8, _hoisted_2)
                                            ]),
                                            _: 2
                                          }, 1032, ["onClick"]))
                                        : _createCommentVNode("", true),
                                      _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                                        default: _withCtx(() => [
                                          _createElementVNode("h3", null, _toDisplayString(item.quantity) + " x " + _toDisplayString(item.productName), 1),
                                          _createElementVNode("p", null, _toDisplayString($setup.t('OrderPage.lineTotal')) + "HK$" + _toDisplayString(Number(item.unitPrice * item.quantity).toLocaleString()), 1)
                                        ]),
                                        _: 2
                                      }, 1024)
                                    ]),
                                    _: 2
                                  }, 1024))
                                }), 128)),
                                _createVNode(_component_ion_item, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_label, null, {
                                      default: _withCtx(() => [
                                        _createElementVNode("b", null, _toDisplayString($setup.t('OrderPage.orderTotal')) + "HK$ " + _toDisplayString(Number($setup.order.totalPrice).toLocaleString()), 1)
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                })
                              ])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_list, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_list_header, { color: "primary" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString($setup.t('OrderPage.deliveryInfo')), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_item, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_label, null, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString($setup.t('CartItemModal.deliveryTime')) + "：" + _toDisplayString($setup.order.deliveryDate) + " ", 1),
                                      ($setup.order.deliveryTime)
                                        ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString($setup.order.deliveryTime), 1))
                                        : _createCommentVNode("", true)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_item, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_label, null, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString($setup.t('deliveryMethod')) + " " + _toDisplayString($setup.order.deliveryMethod), 1)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }),
                              _withDirectives(_createVNode(_component_ion_item, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_label, null, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString($setup.t('deliveryDistrict')) + _toDisplayString($setup.order.deliveryDistrict), 1)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }, 512), [
                                [_vShow, $setup.order.deliveryMethod == '送貨上門']
                              ]),
                              _createVNode(_component_ion_item, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_label, null, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString($setup.t('deliveryAddress')) + _toDisplayString($setup.order.deliveryAddress), 1)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_item, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_label, null, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString($setup.t('customerName')) + _toDisplayString($setup.order.customerName), 1)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_item, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_label, null, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString($setup.t('contactPhone')) + _toDisplayString($setup.order.contactPhone), 1)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_item, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_label, null, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString($setup.t('customerEmail')) + _toDisplayString($setup.order.customerEmail), 1)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}